.sidebar {
  width: 140px;
  background-color: #3d3e3f;
  color: white;
  padding: 20px;
  font-family: 'Arial', sans-serif;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.sidebar h3 {
  font-size: 18px;
  font-weight: bold;
  color: #848587;
  margin-bottom: 20px;
  text-align: center;
  border-bottom: 2px solid #c86673;
  padding-bottom: 10px;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar ul li {
  font-size: 18px;
  margin: 15px 0;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s, padding-left 0.3s;
}

.sidebar ul li:hover {
  background-color: #006a88;
  color: white;
  padding-left: 20px;
}

.sidebar ul li:active {
  background-color: #c86673;
  color: white;
}

.sidebar ul li:last-child {
  margin-top: 30px;
  color: #ed9ea9;
  font-weight: bold;
}

.sidebar ul li:last-child:hover {
  background-color: #c86673;
  color: white;
  padding-left: 20px;
}


  .header {
    background-color: #c86673;
    color: rgb(251, 189, 189);
    padding: 5px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adiciona uma leve sombra */

  }


  .user-info {
    font-size: 16px;
    font-weight: bold;
    margin-right: 10px;
  }
  
  .user-info span {
    color: #c86673; /* Cor do nome do usuário, ajuste conforme necessário */
  }
  

  .footer {
    background-color: #006a88;
    color: white;
    padding: 10px;
    text-align: center;
  }

  .layout {
    display: flex;
    min-height: 100vh; /* Garante que a altura mínima seja de 100% da viewport */
  }
  
  .main-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* Garante que o conteúdo principal também cresça */
  }
  
  .content {
    flex-grow: 1;
    background-color: white;
    padding: 20px;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  th, td {
    padding: 12px;
    border-bottom: 1px solid #ddd;
    text-align: left;
  }
  
  th {
    background-color: #006a88;
    color: white;
  }
  
  tr:hover {
    background-color: #f2f2f2;
  }
  
  .yellow-ball {
    width: 13px;         /* Largura da bola */
    height: 13px;        /* Altura da bola, igual à largura para formar um círculo */
    background-color: rgb(168, 167, 163);  /* Cor de fundo amarela */
    border-radius: 50%;   /* Torna o div um círculo */
    display: inline-block; /* Mantém o div como um bloco em linha */
  }
  .green-ball {
    width: 13px;         /* Largura da bola */
    height: 13px;        /* Altura da bola, igual à largura para formar um círculo */
    background-color: rgb(68, 149, 72);  /* Cor de fundo amarela */
    border-radius: 50%;   /* Torna o div um círculo */
    display: inline-block; /* Mantém o div como um bloco em linha */
  }
  .red-ball {
    width: 13px;         /* Largura da bola */
    height: 13px;        /* Altura da bola, igual à largura para formar um círculo */
    background-color: rgb(187, 30, 19);  /* Cor de fundo amarela */
    border-radius: 50%;   /* Torna o div um círculo */
    display: inline-block; /* Mantém o div como um bloco em linha */
  }

  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  
  .close {
    position: absolute;
    top: 20px;
    right: 30px;
    font-size: 36px;
    color: white;
    cursor: pointer;
    z-index: 10000;
  }
  
  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  
  .modal-image {
    max-width: 100%;
    max-height: 100%;
    transition: transform 0.3s ease-in-out;
  }
  
  .zoom-controls {
    position: absolute;
    bottom: 20px;
    display: flex;
    justify-content: center;
    width: 100%;
  }
  
  .zoom-controls button {
    background-color: #006a88;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin: 0 5px;
  }
  
  .zoom-controls button:hover {
    background-color: #c86673;
  }
  
  /* Renomeie as classes para o modal de e-mail */
.email-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.email-modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 90%;
  text-align: center;
}

.email-modal-header {
  font-size: 24px;
  margin-bottom: 20px;
}

.email-modal-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.email-modal-button {
  background-color: #006a88;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.email-modal-button:hover {
  background-color: #004f6a;
}

.email-modal-textarea {
  width: 80%; /* Ajuste a largura para ocupar 100% do contêiner */
  height: 200px; /* Ajuste a altura conforme necessário */
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical; /* Permite que o usuário ajuste a altura se desejar */

}

/* Estilo do botão */
.button-act {
  background-color: rgba(82, 80, 80, 0); /* Cor de fundo */
  padding: 4;
  color: white; /* Cor do texto */
  border: none; /* Remove a borda padrão */
  border-radius: 15%; /* Torna o botão circular */
  width: 24px; /* Largura do botão */
  height: 24px; /* Altura do botão */
  display: flex; /* Usa flexbox para centralizar o texto */
  align-items: center; /* Alinha verticalmente o texto */
  justify-content: center; /* Alinha horizontalmente o texto */
  font-size: 18px; /* Tamanho da fonte */
  cursor: pointer; /* Mostra o cursor de ponteiro ao passar sobre o botão */
  transition: background-color 0.3s, transform 0.2s; /* Transição suave para cor e transformação */
  color: #303232;
}

.button-act:hover {
  background-color: #2c2f2f; /* Cor de fundo ao passar o mouse */
  color: #f5f5f5;
}

.button-act:active {
  transform: scale(0.95); /* Efeito de clique, reduz o tamanho */
}

/* Estilo do texto */
.button-act span {
  font-weight: bold; /* Deixa o texto mais ousado */
}
.button-act2 {
  background-color: #525050; /* Cor de fundo */
  color: white; /* Cor do texto */
  border: none; /* Remove a borda padrão */
  border-radius: 25%; /* Torna o botão circular */
  width: 24px; /* Largura do botão */
  height: 24px; /* Altura do botão */
  display: flex; /* Usa flexbox para centralizar o texto */
  align-items: center; /* Alinha verticalmente o texto */
  justify-content: center; /* Alinha horizontalmente o texto */
  font-size: 19px; /* Tamanho da fonte */
  cursor: pointer; /* Mostra o cursor de ponteiro ao passar sobre o botão */
  transition: background-color 0.3s, transform 0.2s; /* Transição suave para cor e transformação */
  color: #d0d2d1;
}

.button-act2:hover {
  background-color: #08762e; /* Cor de fundo ao passar o mouse */
}

.button-act2:active {
  transform: scale(0.95); /* Efeito de clique, reduz o tamanho */
}

/* Estilo do texto */
.button-act2 span {
  font-weight: bold; /* Deixa o texto mais ousado */
}

.button-container {
  display: flex; /* Usa flexbox para alinhar os itens horizontalmente */
  gap: 5px; /* Espaço entre os botões */
  justify-content: center; /* Alinha os botões ao centro (opcional) */
}
.button-container2 {
  display: flex; /* Usa flexbox para alinhar os itens horizontalmente */
  
  justify-content: center; /* Alinha os botões ao centro (opcional) */
}


/* Modal Container */
.cancel-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Modal Content */
.cancel-modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
}

/* Textarea */
.cancel-modal-textarea {
  width: 80%;
  height: 150px; /* Aproximadamente 6-7 linhas */
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

/* Buttons */
.cancel-modal-button-container {
  display: flex;
  justify-content: center;
}

.cancel-modal-button {
  padding: 10px 20px;
  margin-left: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.cancel-modal-button.cancel {
  background: #c86673;
  color: white;
}

.cancel-modal-button.close {
  background: #ccc;
  color: black;
}


.filters {
  margin: 20px 0;
  padding: 30px;
  background-color: #f9f9f9;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.filters input,
.filters select {
  margin-right: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.filters input[type="text"] {
  width: 150px;
}

.filters select {
  width: 150px;
}

.filters button {
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  background-color: #006a88;
  color: white;
  cursor: pointer;
}

.buttonRec {
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  background-color: #006a88;
  color: white;
  cursor: pointer;
}

.filters button:hover {
  background-color: #005577;
}

.filters input:focus,
.filters select:focus {
  outline: none;
  border-color: #006a88;
  box-shadow: 0 0 3px rgba(0, 106, 136, 0.5);
}
