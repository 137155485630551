body {
    margin: 0;
    font-family: 'Arial', sans-serif;
    background-color: #f0f0f0;
  }
  
  .login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh;
    background-color: #f0f0f0;
    
  }
  
  .login-box {
    background-color: #ffffff;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(214, 79, 79, 0.201);
    width: 100%;
    max-width: 350px;
    text-align: center;
    
  }
  
  .login-title {
    color: #006a8864;
    margin-bottom: 20px;
    font-size: 24px;
  }
  
  .login-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
    font-size: 16px;
  }
  
  .login-input:focus {
    border-color: #006a88;
    outline: none;
  }
  
  .login-input,
.login-button {
  width: calc(100% - 22px); /* Ajuste para compensar as bordas e margens */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  box-sizing: border-box; /* Inclui padding e borda no cálculo do tamanho */
}

.login-button {
  background-color: #006a88;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 10px;
}

.login-button:hover {
  background-color: #c86673;
}

  
  @media (max-width: 600px) {
    .login-box {
      padding: 20px;
    }
  
    .login-title {
      font-size: 20px;
    }
  
    .login-input, .login-button {
      font-size: 14px;
    }
  }
  .login-title {
    justify-content: center;
    font-size: 35px;
    margin: 4px;
    background-image: linear-gradient(to right, #006a88, #c86673);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    font-weight: bold;
    letter-spacing: '10px';
}
